.page {
  padding: 30px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.m14 {
  margin: 14px;
}

.page-title {
  font-size: 4em;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-24 {
  margin-top: 24px;
}

.p-14 {
  padding: 14px;
}

.ml-14 {
  margin-left: 14px;
}

.text-bold {
  font-weight: bold;
}

.quote-large {
  font-size: 1.5em;
  font-weight: bold;
}

.ref-small {
  font-size: 0.7em;
  font-weight: bold;
}

.width-50pc {
  width: 50%;
}

.width-75pc {
  width: 75%;
}

.margin-auto {
  margin: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.cart-quantity {
  margin: 0px;
  padding: 10px;
  font-weight: bold;
}

.float-right {
  float: right;
}
